@media (min-width: 768px) {
  .ms-md-n1 {
    margin-left: -0.5rem!important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem!important;
  }

  .ms-md-n3 {
    margin-left: -1rem!important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem!important;
  }

  .ms-md-n5 {
    margin-left: -3rem!important;
  }
}
